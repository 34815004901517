<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">mdi-calendar</v-icon>
    </template>
    <v-date-picker
      locale="tr-TR"
      v-model="picker"
      @click="menu = false"
      :min="
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      "
    />
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    picker: {
      get() {
        return this.value;
      },
      set(val) {
        this.menu = false;
        this.$emit("input", val);
      },
    },
  },
};
</script>
